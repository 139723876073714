import { Box, Text } from "@chakra-ui/layout"
import React from "react"
import Layout from "../components/Layout/Layout"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"

const Store = () => {
  return (
    <Layout>
      <Tabs isFitted mt="md">
        <TabList>
          <Tab fontSize={[17, 17, 20, 20]} _active={{ bgColor: "black" }}>
            DRUMKIT
          </Tab>
          <Tab fontSize={[17, 17, 20, 20]} _active={{ bgColor: "black" }}>
            FORMATIONS
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Box textAlign="center" fontSize={25} color="white" my="xl">
              <Text fontSize={[20, 20, 30, 30]}>DRUMKIT COMING SOON</Text>
            </Box>
          </TabPanel>
          <TabPanel>
            <Box textAlign="center" fontSize={25} color="white" my="xl">
              <Text fontSize={[20, 20, 30, 30]}>FORMATIONS COMING SOON</Text>
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Layout>
  )
}

export default Store
